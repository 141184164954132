<template>
  <v-app>
    <v-container>
      <exerciseInforEditor />
    </v-container>
  </v-app>
</template>
<script>
import exerciseInforEditor from "@/components/exercise-toeic-model/exercise-infor-editor";

export default {
  components: {
    exerciseInforEditor,
  },
};
</script>
